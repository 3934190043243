<div class="container">
  <mat-grid-list cols="6" rowHeight="100px">
    <mat-grid-tile
      *ngFor="let tile of tiles"
      [colspan]="tile.cols"
      [class]="tile.class"
    >
      <a [href]="tile.href">
        {{ tile.text }}
      </a>
    </mat-grid-tile>
  </mat-grid-list>

  <mat-grid-list *ngIf="!isTouch" cols="2" rows="5" rowHeight="{{ mobHeight }}">
    <mat-grid-tile rowspan="2" colspan="1">
      <h1 class="mat-display-3">
        Daily Tarot - <br />
        Discover something <br />
        new everyday !
      </h1>
    </mat-grid-tile>
    <mat-grid-tile rowspan="4" colspan="1">
      <img
        class="phones"
        src="../../assets/images/phones.png"
        alt="Phones displaying the app"
      />
    </mat-grid-tile>
    <mat-grid-tile rowspan="1" colspan="1">
      <h2 class="body-1, secondTitle">
        Have you ever wanted to discover<br />
        something new about yourself ? <br />
        Then this is the app for you.
      </h2>
    </mat-grid-tile>
    <mat-grid-tile rowspan="1" colspan="1">
      <a
        href="https://play.google.com/store/apps/details?id=com.lucasgoldner.DailyTarotApp"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img
          class="downloadImage"
          src="../../assets/images/playstore.png"
          alt="Download on Playstore"
        />
      </a>
      <a
        href="https://play.google.com/store/apps/details?id=com.lucasgoldner.DailyTarotApp"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img
          class="downloadImage"
          src="../../assets/images/appstore.png"
          alt="Download on Appstore"
        /> </a
    ></mat-grid-tile>
  </mat-grid-list>

  <mat-grid-list *ngIf="isTouch" cols="1" rowHeight="6rem">
    <mat-grid-tile rowspan="4">
      <h1 class="mat-display-3" style="padding: 1.5rem">
        Daily Tarot - <br />
        Discover something <br />
        new everyday !
      </h1>
    </mat-grid-tile>
    <mat-grid-tile rowspan="1">
      <h2 class="body-1">
        Have you ever wanted to discover<br />
        something new about yourself ? <br />
        Then this is the app for you.
      </h2>
    </mat-grid-tile>
    <mat-grid-tile rowspan="2">
      <a
        href="https://play.google.com/store/apps/details?id=com.lucasgoldner.DailyTarotApp"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img
          class="MobDownloadImage"
          src="../../assets/images/playstore.png"
          alt="Download on Playstore"
        />
      </a>
      <a
        href="https://play.google.com/store/apps/details?id=com.lucasgoldner.DailyTarotApp"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img
          class="MobDownloadImage"
          src="../../assets/images/appstore.png"
          alt="Download on Appstore"
        />
      </a>
    </mat-grid-tile>
    <mat-grid-tile rowspan="4">
      <img
        class="mobPhones"
        src="../../assets/images/phones.png"
        alt="Phones displaying the app"
      />
    </mat-grid-tile>
  </mat-grid-list>

  <div class="custom-shape-divider-top-1606174240">
    <svg
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1200 120"
      preserveAspectRatio="none"
    >
      <path d="M1200 0L0 0 598.97 114.72 1200 0z" class="shape-fill"></path>
    </svg>
  </div>
</div>
