<mat-grid-list cols="1" rowHeight="2.5rem" id="about">
  <mat-grid-tile rowspan="2">
    <h2 class="pageTitle">About Us</h2>
  </mat-grid-tile>
  <mat-grid-tile rowspan="2">
    <h2 class=".mat-display-2, h2Title">What is Daily Tarot</h2>
  </mat-grid-tile>
  <mat-grid-tile rowspan="7">
    <h3 class=".mat-body-1" style="padding: 10px">
      Tarot cards are unlike popular belief not for fortune telling. In Daily
      Tarot you will receive<br />
      every day a new tarot card, which has a description. By following the
      description you<br />
      could notice different things happening in your surroundings or get
      comfortable with a new<br />
      kind of energy. At the end of the day you can go write what happened on
      that day.<br />
      Over time you will grow a collection of events, which could influence you
      in a special way.
    </h3>
  </mat-grid-tile>
</mat-grid-list>
