<div class="fiveContainer" [ngStyle]="{ height: height }">
  <mat-grid-list cols="1" rowHeight="10rem">
    <mat-grid-tile rowspan="1">
      <div class="con">
        <div class="line"></div>
        <h2 class=".mat-title" id="download">Download</h2>
      </div>
    </mat-grid-tile>
    <mat-grid-tile rowspan="1">
      <p class="subheading-1">
        What are you waiting for ? <br />
        Download the app now from the PlayStore or AppStore
      </p>
    </mat-grid-tile>
    <mat-grid-tile rowspan="1">
      <a
        href="https://play.google.com/store/apps/details?id=com.lucasgoldner.DailyTarotApp"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img
          class="MobDownloadImage"
          src="../../assets/images/playstore.png"
          alt="Download on Playstore"
        />
      </a>
      <a
        href="https://play.google.com/store/apps/details?id=com.lucasgoldner.DailyTarotApp"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img
          class="MobDownloadImage"
          src="../../assets/images/appstore.png"
          alt="Download on Appstore"
        />
      </a>
    </mat-grid-tile>
    <mat-grid-tile rowspan="1">
      <div class="conXtra" id="contact">
        <a
          href="https://linkedin.lucas-goldner.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src="../../assets/images/linkedin.png"
            class="hoverMan"
            style="height: 3.2rem"
            alt="LinkedIn of Lucas Goldner"
          />
        </a>
        <a
          href="https://github.lucas-goldner.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src="../../assets/images/github.png"
            class="hoverMan"
            style="height: 5rem"
            alt="Github of Lucas Goldner"
          />
        </a>
        <a
          href="https://twitter.lucas-goldner.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src="../../assets/images/twitter.png"
            class="hoverMan"
            style="height: 3.6rem"
            alt="Twitter of Lucas Goldner"
          />
        </a>
      </div>
    </mat-grid-tile>
  </mat-grid-list>
</div>
